import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { getCourseNavigation } from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"

import Box from "../../../../../components/box"
import Paragraph from "../../../../../components/paragraph"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Heading from "../../../../../components/heading"
import NotationSingle from "../../../../../components/notation-single"
import PaperStack from "../../../../../components/paper-stack"
import Poem from "../../../../../components/poem"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import VideoPlayer from "../../../../../components/video-player"

const Page = () => {
  const navigation = getCourseNavigation({ courseId: "haelfte-des-lebens" })

  const data = useStaticQuery(graphql`
    query AlliterationenAssonanzenQuery {
      poster: file(
        relativePath: { eq: "kurse/poster-ueber-hoelderlins-laute.png" }
      ) {
        ...largeImage
      }
    }
  `)

  return (
    <LayoutCourse
      navigation={navigation}
      footer={
        <CourseFooterNext to="/kurse/haelfte-des-lebens/01-zwei-haelften/versmass-bestimmen" />
      }
    >
      <Seo title="Alliterationen und Assonanzen" />
      <Box>
        <Stack>
          <Stack space={3}>
            <Heading as="h1" level={2}>
              Alliterationen und Assonanzen
            </Heading>
          </Stack>
          <Paragraph>
            Hölderlin hat die Wörter seiner Gedichte weniger nach den Regeln der
            Grammatik, sondern vielmehr nach musikalischen Prinzipien
            zusammengesetzt. Aus der Kombination von Wörtern mit bestimmten
            Vokalen und Konsonanten ergibt sich so wie in einem Lied eine Art
            Sprachmelodie. Das lässt sich auch in ›Hälfte des Lebens‹
            beobachten.
          </Paragraph>
          <Paragraph>
            Die 1. Strophe beginnt mit einer Serie von e-e und i-e-Variationen.
            Mehr und mehr kommen dazu auch dunklere und vollere Vokale wie o und
            u hinzu, die die Fülle des Landschaftsbildes akkustisch
            unterstreichen.
          </Paragraph>
          <PaperStack>
            <Poem>
              <p>
                Mit g<NotationSingle>e</NotationSingle>lben B
                <NotationSingle>i</NotationSingle>rnen h
                <NotationSingle>ä</NotationSingle>ng
                <NotationSingle>e</NotationSingle>t
              </p>
              <p>
                <NotationSingle>U</NotationSingle>nd v
                <NotationSingle>o</NotationSingle>ll m
                <NotationSingle>i</NotationSingle>t w
                <NotationSingle>i</NotationSingle>ld
                <NotationSingle>e</NotationSingle>n R
                <NotationSingle>o</NotationSingle>s
                <NotationSingle>e</NotationSingle>n
              </p>
              <p>
                Das Land <NotationSingle>i</NotationSingle>n den S
                <NotationSingle>ee</NotationSingle>,
              </p>
              <p>
                <NotationSingle>I</NotationSingle>hr h
                <NotationSingle>o</NotationSingle>ld
                <NotationSingle>e</NotationSingle>n Schw
                <NotationSingle>ä</NotationSingle>n
                <NotationSingle>e</NotationSingle>,
              </p>
              <p>
                <NotationSingle>U</NotationSingle>nd tr
                <NotationSingle>u</NotationSingle>nk
                <NotationSingle>e</NotationSingle>n von K
                <NotationSingle>ü</NotationSingle>ss
                <NotationSingle>e</NotationSingle>n
              </p>
              <p>
                T<NotationSingle>u</NotationSingle>nkt{" "}
                <NotationSingle>i</NotationSingle>hr das Haupt
              </p>
              <p>
                <NotationSingle>I</NotationSingle>ns heilign
                <NotationSingle>ü</NotationSingle>cht
                <NotationSingle>e</NotationSingle>rne Wasser.
              </p>
            </Poem>
          </PaperStack>

          <Paragraph>
            In der klanglichen Gestaltung des Gedichts ist zudem eine weitere
            Symmetrie versteckt: Das dritte Wort der 1. Strophe (Birnen) bildet
            eine Assonanz mit dem drittletzten Wort der 2. Strophe (Klirren):
            Beide Wörter haben dieselben Vokale in derselben Silbenposition.
            Weitere Beispiele für Assonanzen wären z.B. Hegel und Kegel oder
            Handbuch und Strandtuch.
          </Paragraph>

          <Paragraph>
            Das letzte Wort der 1. Strophe (Wasser) zieht außerdem eine Folge
            von Worten mit dem Anlaut W nach sich. Die gesamte 2. Strophe ist
            erfüllt von dem Klagelaut, mit dem sie eröffnet wird: »Weh mir«.
            Eine solche Folge von Worten mit demselben Anlaut nennt man eine
            Alliteration.
          </Paragraph>
          <PaperStack>
            <Poem>
              <p>Und trunken von Küssen</p>
              <p>Tunkt ihr das Haupt</p>
              <p>
                Ins heilignüchterne <NotationSingle>W</NotationSingle>asser.
              </p>
              <br />
              <p>
                <NotationSingle>W</NotationSingle>eh mir,{" "}
                <NotationSingle>w</NotationSingle>o nehm’ ich,{" "}
                <NotationSingle>w</NotationSingle>enn
              </p>
              <p>
                Es <NotationSingle>W</NotationSingle>inter ist, die Blumen, und{" "}
                <NotationSingle>w</NotationSingle>o
              </p>
              <p>Den Sonnenschein,</p>
              <p>Und Schatten der Erde?</p>
              <p>Die Mauern stehn</p>
              <p>
                Sprachlos und kalt, im <NotationSingle>W</NotationSingle>inde
              </p>
              <p>Klirren die Fahnen.</p>
            </Poem>
          </PaperStack>
          <Paragraph>
            Mehr über Hölderlins Einsatz von Vokalen und Konsonanten erklärt der
            Sprechkünstler Christian Reiner in diesem Video...
          </Paragraph>
          <VideoPlayer
            src="kurse/ueber-hoelderlins-laute"
            poster={data.poster}
          />
        </Stack>
      </Box>
    </LayoutCourse>
  )
}

export default Page
